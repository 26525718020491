<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-1">
      <div>
        <b-form-select
          v-model="perPage"
          :options="perPageOptions"
          size="sm"
          class="w-75"
          @change="handlePerPageChange"
        ></b-form-select>
      </div>
      <div v-if="totalRows > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </div>
    
    <b-table 
      small 
      :fields="fields" 
      :items="items" 
      responsive="sm" 
      class="mt-1"
      :busy="loading"
      show-empty
      :empty-text="$t('Nenhuma operação encontrada')"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          {{ $t('Carregando...') }}
        </div>
      </template>
      
      <template #cell(date)="data">
        {{ formatDate(data.item.date) }}
      </template>

      <template #cell(active)="data">
        {{ data.item.active }}
      </template>

      <template #cell(type)="data">
        {{ $t(data.item.type) }}
      </template>

      <template #cell(action)="data">
        {{ $t(data.item.buySell) }}
      </template>

      <template #cell(price)="data">
        <div>
          {{ moneyFormat(data.item.price) }}
        </div>
      </template>

      <template #cell(percent)="data">
        <span :class="data.item.percent > 0 ? 'text-success' : data.item.percent < 0 && 'text-danger'">
          {{ parseFloat(data.item.percent).toFixed(2) }}%
        </span>
      </template>
    </b-table>
    
    <div class="d-flex justify-content-between align-items-center mt-2" v-if="totalRows > 0">
      <div>
        <small>{{ $t('Mostrando') }} {{ Math.min(items.length, perPage) }} {{ $t('de') }} {{ totalRows }} {{ $t('registros') }}</small>
      </div>
      <div v-if="totalRows > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
  
<script>
import { BTable, BProgress, BBadge, BPagination, BFormSelect, BSpinner } from 'bootstrap-vue'
const { format } = require('date-fns');

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BPagination,
    BFormSelect,
    BSpinner
  },
  props: {
    robotId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: [
        { key: 'date', label: this.$i18n.t('Data / Hora') },
        { key: 'active', label: this.$i18n.t('Ativo') },
        { key: 'type', label: this.$i18n.t('Tipo') },
        { key: 'action', label: this.$i18n.t('Operação') },
        { key: 'price', label: this.$i18n.t('Preço') },
        { key: 'percent', label: this.$i18n.t('Resultado') },
      ],
      items: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      perPageOptions: [
        { value: 5, text: '5 ' + this.$i18n.t('por página') },
        { value: 10, text: '10 ' + this.$i18n.t('por página') },
        { value: 15, text: '15 ' + this.$i18n.t('por página') },
        { value: 20, text: '20 ' + this.$i18n.t('por página') },
        { value: 50, text: '50 ' + this.$i18n.t('por página') },
      ],
      loading: true
    }
  },
  mounted() {
    console.log('TableResults mounted with robotId:', this.robotId);
    this.fetchData();
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    handlePerPageChange() {
      this.currentPage = 1; // Reset to first page when changing items per page
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      console.log('Fetching data for robotId:', this.robotId, 'page:', this.currentPage, 'limit:', this.perPage);
      
      this.$store.dispatch('getOrdersHistory', {
        robotId: this.robotId,
        page: this.currentPage,
        limit: this.perPage
      })
      .then(response => {
        console.log('Response received:', response);
        if (response && Array.isArray(response.data)) {
          this.items = response.data;
          this.totalRows = response.total || 0;
        } else {
          this.items = [];
          this.totalRows = 0;
          console.error('Invalid response format:', response);
        }
        this.loading = false;
      })
      .catch(error => {
        console.error('Error fetching order history:', error);
        this.items = [];
        this.totalRows = 0;
        this.loading = false;
        this.$bvToast.toast(this.$t('Erro ao carregar histórico de operações'), {
          title: this.$t('Erro'),
          variant: 'danger',
          solid: true
        });
      });
    },
    formatDate(date) {
      if (!date) return '--';
      try {
        return format(new Date(date), 'dd/MM/yyyy HH:mm');
      } catch (error) {
        return '--';
      }
    },
  }
}
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}
</style>